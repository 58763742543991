import React, { ReactElement, useEffect, useState } from 'react'
import { LandingBackgroundSvg } from './svg/LandingBackgroundSvg'
import {
    AppBar,
    Box,
    Button,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
    useTheme,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { useLocalization } from '../../localization/LocalizationProvider'

interface Page {
    label: string
    elementId: string
}

const minScroll = 150

const pages: Page[] = [
    // { label: 'Products', elementId: 'section__products' },
    // { label: 'About Us', elementId: 'section__about-us' },
]

export const LandingHeader = (): ReactElement => {
    const theme = useTheme()
    const { translate } = useLocalization()

    const [isMinimumScroll, setIsMinimumScroll] = useState(false)
    const [scrollPercentage, setScrollPercentage] = useState(0)

    const [anchorElNav, setAnchorElNav] = useState<HTMLElement | null>(null)

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>): void => {
        setAnchorElNav(event.currentTarget)
    }

    const handleCloseNavMenu = (): void => {
        setAnchorElNav(null)
    }

    const handlePageClick = (page: Page): void => {
        handleCloseNavMenu()

        const element = document.getElementById(page.elementId)

        if (element) {
            scrollTo({
                top: element.offsetTop - 250,
                behavior: 'smooth',
            })
        }
    }

    const handleBrandClick = (): void => {
        scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    useEffect(() => {
        const onScroll = (): void => {
            const scroll = window.scrollY

            if (scroll >= minScroll) {
                setIsMinimumScroll(true)
            }

            if (scroll < minScroll) {
                setIsMinimumScroll(false)
            }

            setScrollPercentage(1 - Math.max((minScroll - scroll) / minScroll, 0))
        }

        document.addEventListener('scroll', onScroll)

        return (): void => {
            document.removeEventListener('scroll', onScroll)
        }
    }, [])

    const renderAppBar = (): ReactElement => {
        return (
            <AppBar
                position="fixed"
                elevation={isMinimumScroll ? 1 : 0}
                style={{
                    transition: 'all 150ms',
                }}
            >
                <Container>
                    <Toolbar
                        disableGutters
                        style={{
                            ...(isMinimumScroll
                                ? {}
                                : {
                                      minHeight: 64 + (1 - scrollPercentage) * (minScroll - 64),
                                  }),
                        }}
                    >
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', sm: 'none' },
                                }}
                            >
                                {pages.map((page) => (
                                    <MenuItem key={page.elementId} onClick={(): void => handlePageClick(page)}>
                                        <Typography textAlign="center">{page.label}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' }, gap: '2rem' }}>
                            {pages.map((page) => (
                                <Button
                                    key={page.elementId}
                                    onClick={(): void => handlePageClick(page)}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    {page.label}
                                </Button>
                            ))}
                        </Box>

                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{
                                fontFamily: 'Poppins',
                                fontWeight: 'bold',
                                flexGrow: 0,
                                display: 'flex',
                                cursor: 'pointer',
                                transition: 'opacity 150ms',
                                opacity: scrollPercentage, // isMinimumScroll ? 1 : 0,
                            }}
                            onClick={handleBrandClick}
                        >
                            {translate('uoLabs')}
                        </Typography>
                    </Toolbar>
                </Container>
            </AppBar>
        )
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ height: '10rem', backgroundColor: theme.palette.primary.main }}>{renderAppBar()}</Box>
            <Box
                sx={{
                    height: '60vh',
                    position: 'relative',
                    '& > svg': {
                        display: 'block',
                    },
                }}
            >
                <LandingBackgroundSvg />
            </Box>

            <Box
                sx={{
                    color: 'white',
                    position: 'absolute',
                    top: '10rem',
                    right: 0,
                    left: 0,
                }}
            >
                <Container>
                    <Box
                        sx={{
                            textAlign: 'right',
                        }}
                    >
                        <Typography component="div" variant="h1" style={{ fontWeight: 'bold' }}>
                            {translate('uoLabs')}
                        </Typography>
                        <Typography component="div" variant="h3" style={{ fontWeight: '500' }}>
                            {translate('landingCaption')}
                        </Typography>
                    </Box>
                </Container>
            </Box>
        </Box>
    )
}
