import React, { ReactElement } from 'react'
import { Typography, useTheme } from '@mui/material'
import { useLocalization } from '../../../localization/LocalizationProvider'

export const Vision = (): ReactElement => {
    const theme = useTheme()
    const { translate } = useLocalization()

    return (
        <>
            <Typography variant="h2" color={theme.palette.primary.main} style={{ fontWeight: 'bold' }}>
                {translate('visionOur')}
            </Typography>
            <Typography variant="h2" color={theme.palette.primary.main} style={{ fontWeight: 'bold' }}>
                {translate('visionVision')}
            </Typography>
            <Typography style={{ fontSize: '1.8rem', marginTop: '2rem' }}>{translate('visionCaption')}</Typography>
        </>
    )
}
