import React, { ReactElement, useState } from 'react'
import { Section } from '../../molecules/Section'
import { useLocalization } from '../../localization/LocalizationProvider'
import { Box, Button, TextField } from '@mui/material'
import { Send } from '@mui/icons-material'

export const ContactSection = (): ReactElement => {
    const [name, setName] = useState('')
    // const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    const onSendClick = (): void => {
        window.open(`mailto:contact@uolabs.tech?subject=${name}&body=${message}`)
    }

    const { translate } = useLocalization()

    return (
        <Section title={translate('contactTitle')} caption={translate('contactCaption')}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2rem',
                }}
            >
                <TextField
                    label={translate('contactName')}
                    variant="outlined"
                    value={name}
                    onChange={(e): void => setName(e.target.value)}
                />
                {/* <TextField*/}
                {/*    label={translate('contactEmail')}*/}
                {/*    variant="outlined"*/}
                {/*    value={email}*/}
                {/*    onChange={(e): void => setEmail(e.target.value)}*/}
                {/* />*/}
                <TextField
                    label={translate('contactMessage')}
                    variant="outlined"
                    value={message}
                    onChange={(e): void => setMessage(e.target.value)}
                    multiline
                    rows={5}
                />
                <Button endIcon={<Send />} size="large" variant="contained" onClick={onSendClick}>
                    Send
                </Button>
            </Box>
        </Section>
    )
}
