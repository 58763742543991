import { useEffect, useState } from 'react'

export enum Breakpoint {
    S = 'S',
    M = 'M',
    L = 'L',
    XL = 'XL',
}

export const breakpoints = {
    [Breakpoint.S]: 0,
    [Breakpoint.M]: 768,
    [Breakpoint.L]: 1024,
    [Breakpoint.XL]: 1200,
}

const breakpointValues = [Breakpoint.S, Breakpoint.M, Breakpoint.L, Breakpoint.XL]

const getCurrentBreakpoint = (): Breakpoint => {
    let breakpoint = Breakpoint.S
    breakpointValues.forEach((breakpointKey) => {
        const breakpointPixels = breakpoints[breakpointKey]
        if (breakpointPixels > window.innerWidth) return false
        breakpoint = breakpointKey
    })
    return breakpoint
}

export const useBreakpoint = (): Breakpoint => {
    const [breakpoint, setBreakpoint] = useState(getCurrentBreakpoint())

    useEffect(() => {
        const resizeCallback = (): void => {
            setBreakpoint(getCurrentBreakpoint())
        }

        window.addEventListener('resize', resizeCallback)

        return (): void => window.removeEventListener('resize', resizeCallback)
    }, [])

    return breakpoint
}
