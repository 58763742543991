import { createTheme } from '@mui/material'
import { fontFaces } from '../../assets/fonts/fontFaces'

export const landingTheme = createTheme({
    palette: {
        primary: {
            main: '#D9415A',
        },
        secondary: {
            main: '#FFA440',
        },
        tertiary: {
            main: '#41D95E',
        },
        quaternary: {
            main: '#FF6680',
        },
        quinary: {
            main: '#04D89D',
        },
        text: {
            primary: 'rgba(0, 0, 0, 0.84)',
            secondary: 'white',
        },
    },
    typography: {
        fontFamily: 'Poppins',
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                ${fontFaces.join('\n')}
            `,
        },
        MuiInputLabel: {
            defaultProps: {
                style: {
                    color: 'rgba(0, 0, 0, 0.6)',
                },
            },
        },
    },
})
