import React, { ReactElement } from 'react'
import { useTheme } from '@mui/material'

export const HalfColorBackgroundSvg = (): ReactElement => {
    const theme = useTheme()

    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 34 20"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid slice"
        >
            <path
                fill={theme.palette.primary.main}
                d="M 0 1 Q 4 2 8 1 Q 12 0 15 0 Q 19 0 20 4 C 21 8 18 8 17 11 Q 15 16 19 18 Q 24 20 28 18 Q 31 17 34 17 V 20 H 0 Z"
            />
        </svg>
    )
}
