import React, { ReactElement } from 'react'
import { BrowserRouter, Routes } from 'react-router-dom'
import { getInsectRoutes } from '../insects/routes'
import { getLandingRoutes } from '../web/routes'

export const Router = (): ReactElement => {
    return (
        <BrowserRouter>
            <Routes>
                {getInsectRoutes('/insects')}
                {getLandingRoutes('/')}
            </Routes>
        </BrowserRouter>
    )
}
