import React, { ReactElement } from 'react'
import { Section } from '../../molecules/Section'
import { BulletList, BulletListItem } from '../../molecules/BulletList'
import { CommonSectionProps } from '../CommonSectionProps'

const SOFTWARE_ITEMS: BulletListItem[] = [
    {
        icon: 'expand',
        title: 'Develop robust and scalable services',
    },
    {
        icon: 'expand',
        title: 'Design scalable solutions',
    },
    {
        icon: 'expand',
        title: 'Code using good practices',
    },
    {
        icon: 'expand',
        title: 'Build automated deployment and QA pipelines',
    },
    {
        icon: 'expand',
        title: 'Experience in cloud market leader platforms',
    },
    {
        icon: 'expand',
        title: 'Application security and data protection',
    },
]

type SoftwareExpertiseSectionProps = CommonSectionProps

export const SoftwareExpertiseSection = ({
    variant = 'light',
    alignment = 'left',
    divider = 0,
    sx,
    dividerSx,
}: SoftwareExpertiseSectionProps): ReactElement => {
    return (
        <Section
            variant={variant}
            alignment={alignment}
            spacedTop={false}
            title="Software solutions"
            caption="All of our AI solutions come hand by hand of professional software architecture designs"
            dividerProps={{
                variant: divider,
                sx: dividerSx,
            }}
            sx={{
                paddingTop: '20rem',
                ...sx,
            }}
        >
            <BulletList
                items={SOFTWARE_ITEMS}
                columns={{
                    s: 1,
                    l: 2,
                }}
                variant={variant}
            />
        </Section>
    )
}
