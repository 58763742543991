import React, { ReactElement } from 'react'
import { SectionDividerSvgProps } from '../SectionDividerSvgProps'
import { useTheme } from '@mui/material'

export const SectionDividerSvg2 = ({ variant = 'light' }: SectionDividerSvgProps): ReactElement => {
    const theme = useTheme()

    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 17 5"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid slice"
        >
            <path
                fill={variant === 'light' ? theme.palette.primary.main : 'white'}
                d="M 0 3 Q 5 1 9 3 Q 13 5 17 5 V 0 H 0 Z"
            />
        </svg>
    )
}
