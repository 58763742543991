export interface CreateFontFaceParams {
    family: string
    style?: 'normal' | 'italic'
    weight?: string
    url: string
    format?: string
}

export const createFontFace = ({
    family,
    style = 'normal',
    weight = '400',
    url,
    format = 'ttf',
}: CreateFontFaceParams): string => {
    return `
        @font-face {
            font-family: '${family}';
            font-style: ${style};
            font-weight: ${weight};
            src: local('${family}-${weight}-${style}'), url(${url}) format('${format}');
        }
    `
}
