import React, { ReactElement } from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { Circle } from '@mui/icons-material'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'
import { Translation } from '../../../localization/translations'

interface BulletItemProps {
    icon?: string
    title: Translation
    detail?: string
    variant?: 'light' | 'dark'
    sx?: SxProps<Theme>
}

export const BulletItem = ({ title, detail, variant = 'light', sx }: BulletItemProps): ReactElement => {
    const theme = useTheme()

    const renderIcon = (): ReactElement => {
        return (
            <Box
                sx={{
                    position: 'relative',
                    width: '3rem',
                    height: '3rem',
                }}
            >
                <Circle
                    color="secondary"
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                />
                {/* {icon ? (*/}
                {/*    <img*/}
                {/*        src={`/icons/${icon}.png`}*/}
                {/*        style={{*/}
                {/*            position: 'absolute',*/}
                {/*            left: '50%',*/}
                {/*            top: '50%',*/}
                {/*            width: '80%',*/}
                {/*            height: '80%',*/}
                {/*            transform: 'translate(-70%, -30%)',*/}
                {/*        }}*/}
                {/*    />*/}
                {/* ) : null}*/}
            </Box>
        )
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                gap: '1.4rem',
                color: variant === 'light' ? theme.palette.text.primary : theme.palette.text.secondary,
                ...sx,
            }}
        >
            {renderIcon()}
            <Box
                sx={{
                    flex: 1,
                    marginTop: '0.4rem',
                }}
            >
                <Typography fontWeight="bold" fontSize="1.4rem">
                    {title}
                </Typography>
                {detail ? <Typography fontWeight={300}>{detail}</Typography> : null}
            </Box>
        </Box>
    )
}
