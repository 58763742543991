import React, { ReactElement } from 'react'
import { Box } from '@mui/material'
import { SectionDividerSvgProps } from './SectionDividerSvgProps'
import { SectionDividerSvg1 } from './svg/SectionDividerSvg1'
import { SectionDividerSvg2 } from './svg/SectionDividerSvg2'
import { SectionDividerSvg3 } from './svg/SectionDividerSvg3'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'

const sectionDividers: ((dividerSvgProps: SectionDividerSvgProps) => ReactElement)[] = [
    SectionDividerSvg1,
    SectionDividerSvg2,
    SectionDividerSvg3,
]

export interface SectionDividerProps {
    variant: 0 | 1 | 2
    colorVariant?: 'light' | 'dark'
    className?: string
    sx?: SxProps<Theme>
}

export const SectionDivider = ({ variant, colorVariant, className, sx }: SectionDividerProps): ReactElement => {
    const Divider = sectionDividers[variant]
    return (
        <Box
            sx={{
                position: 'absolute',
                left: 0,
                top: 0,
                right: 0,
                zIndex: 10,
                ...sx,
            }}
            className={className}
        >
            <Divider variant={colorVariant} />
        </Box>
    )
}
