import React, { ReactElement } from 'react'
import { LocalizationContext } from './LocalizationProvider'
import gbEn from './translations/gb-en'
import { TranslationKey } from './translations'

interface LocalizationContainerProps {
    children?: null | string | ReactElement | (null | string | ReactElement)[]
}

export const LocalizationContainer = (props: LocalizationContainerProps): ReactElement => {
    const translate = (key: TranslationKey): string | ReactElement | (string | ReactElement)[] => {
        return gbEn[key] ?? ''
    }

    return (
        <LocalizationContext.Provider
            value={{
                translate,
            }}
        >
            {props.children}
        </LocalizationContext.Provider>
    )
}
