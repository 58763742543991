import React, { ReactElement } from 'react'
import { useTheme } from '@mui/material'

export const LandingBackgroundSvg = (): ReactElement => {
    const theme = useTheme()

    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 42 12"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid slice"
        >
            <path fill={theme.palette.secondary.main} d="M0 8q3-2 7-2t9 3 10 3q5 0 9-1t7-1V0H0" />
            <path fill={theme.palette.quaternary.main} d="M0 4q3 1 7 1t8-2 9 2q5 4 10 4t8-1V0H0" />
            <path fill={theme.palette.primary.main} d="M0 2q2-1 6-1t7 3 7 4q6 1 11-1t11-2V0H0" />
        </svg>
    )
}
