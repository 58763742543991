import React, { ReactElement } from 'react'

import { LandingHeader } from './molecules/LandingHeader'
import { Box, CssBaseline, ThemeProvider } from '@mui/material'
import { Footer } from './molecules/Footer'
import { AboutUsSection } from './sections/AboutUsSection'
import { MissionVisionSection } from './sections/MissionVisionSection'
import { CommonSectionProps } from './sections/CommonSectionProps'
import { DataAnalyticsSection } from './sections/DataAnalyticsSection'
import { SoftwareExpertiseSection } from './sections/SoftwareExpertiseSection'
import { AIFieldsSection } from './sections/AIFieldsSection'
import { SectionDivider } from './molecules/SectionDivider'
import { Section } from './molecules/Section'
import { Breakpoint, useBreakpoint } from './hooks/useBreakpoint'
import { LocalizationContainer } from './localization/LocalizationContainer'
import { landingTheme } from './theme/landingTheme'
import { ContactSection } from './sections/ContactSection'

const SECTIONS: React.FunctionComponent<CommonSectionProps>[] = [
    DataAnalyticsSection,
    AIFieldsSection,
    SoftwareExpertiseSection,
]

const LandingApp = (): ReactElement => {
    const breakpoint = useBreakpoint()

    return (
        <ThemeProvider theme={landingTheme}>
            <CssBaseline />
            <LocalizationContainer>
                <LandingHeader />
                <Box sx={{ gap: '7rem' }}>
                    <AboutUsSection />
                    <MissionVisionSection />
                    {SECTIONS.map((SectionComponent, index) => {
                        const variant = index % 2 === 0 ? 'dark' : 'light'
                        const alignment = index % 2 === 0 ? 'left' : 'right'
                        const divider = index % 2 === 0 ? 1 : 0

                        return (
                            <SectionComponent
                                key={`section__${index}`}
                                variant={variant}
                                alignment={alignment}
                                divider={divider}
                                sx={breakpoint !== Breakpoint.S && index === 0 ? { paddingTop: 0 } : {}}
                                dividerSx={
                                    index === 0
                                        ? {
                                              '@media screen and (min-width: 769px)': {
                                                  display: 'none',
                                              },
                                          }
                                        : {}
                                }
                            />
                        )
                    })}
                    <Section spacedTop={false}>
                        <SectionDivider variant={2} colorVariant="light" />
                    </Section>
                    <Section>
                        <ContactSection />
                    </Section>
                </Box>
                <Footer />
            </LocalizationContainer>
        </ThemeProvider>
    )
}

export default LandingApp
