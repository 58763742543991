import React, { ReactElement } from 'react'
import { Section } from '../../molecules/Section'
import { HalfColorBackgroundSvg } from './svg/HalfColorBackgroundSvg'
import { Box } from '@mui/material'
import { Mission } from './components/Mission'
import { Vision } from './components/Vision'

export const MissionVisionSectionLarge = (): ReactElement => {
    const renderMission = (): ReactElement => {
        return (
            <Box
                sx={{
                    position: 'absolute',
                    left: '4rem',
                    right: '50%',
                    top: 200,
                }}
            >
                <Mission />
            </Box>
        )
    }

    const renderVision = (): ReactElement => {
        return (
            <Box
                sx={{
                    position: 'absolute',
                    left: '50%',
                    right: '4rem',
                    bottom: 250,
                    textAlign: 'right',
                }}
            >
                <Vision />
            </Box>
        )
    }

    return (
        <Section fullWidth spacedTop={false} spacedBottom={false}>
            <Box
                sx={{
                    height: '120vh',
                    position: 'relative',
                    '& > svg': {
                        display: 'block',
                    },
                }}
            >
                <HalfColorBackgroundSvg />
            </Box>
            {renderMission()}
            {renderVision()}
        </Section>
    )
}
