import { createFontFace, CreateFontFaceParams } from '../fontUtils'

import PoppinsBold from './Poppins-Bold.woff2'
import PoppinsMedium from './Poppins-Medium.woff2'
import PoppinsLight from './Poppins-Light.woff2'
import PoppinsThin from './Poppins-Thin.woff2'

const fontFamily = 'Poppins'
const fontFormat = 'woff2'

const fontFaceDefinitions: CreateFontFaceParams[] = [
    {
        family: fontFamily,
        weight: '700',
        url: PoppinsBold,
        format: fontFormat,
    },
    {
        family: fontFamily,
        weight: '500',
        url: PoppinsMedium,
        format: fontFormat,
    },
    {
        family: fontFamily,
        weight: '400',
        url: PoppinsLight,
        format: fontFormat,
    },
    {
        family: fontFamily,
        weight: '300',
        url: PoppinsThin,
        format: fontFormat,
    },
]

export const poppinsFontFaces = fontFaceDefinitions.map(createFontFace)
