import React, { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppBar, Container, Toolbar, Typography } from '@mui/material'

export const NavBar = (): ReactElement => {
    const navigate = useNavigate()

    const onUoLabsClick = (): void => {
        navigate('/')
    }

    return (
        <AppBar position="fixed">
            <Container>
                <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
                    <Typography fontWeight="bold" fontSize="1.5rem">
                        Insect Counter
                    </Typography>
                    <Typography
                        fontWeight="bold"
                        fontSize="1.5rem"
                        sx={{
                            cursor: 'pointer',
                        }}
                        onClick={onUoLabsClick}
                    >
                        UoLabs
                    </Typography>
                </Toolbar>
            </Container>
        </AppBar>
    )
}
