import React, { ReactElement } from 'react'
import { Box, Container, Typography, useTheme } from '@mui/material'
import { SectionDivider, SectionDividerProps } from '../SectionDivider'
import { SxProps } from '@mui/system'
import { Breakpoint, useBreakpoint } from '../../hooks/useBreakpoint'
import { Theme } from '@mui/material/styles'
import { Translation } from '../../localization/translations'

interface SectionProps {
    title?: Translation
    caption?: string | ReactElement | (string | ReactElement)[]
    variant?: 'dark' | 'light'
    alignment?: 'left' | 'right'
    children?: null | string | ReactElement | (null | string | ReactElement)[]
    fullWidth?: boolean
    spacedTop?: boolean
    spacedBottom?: boolean
    dividerProps?: Partial<SectionDividerProps>
    sx?: SxProps<Theme>
}

export const Section = ({
    title,
    caption,
    variant = 'light',
    alignment,
    children,
    fullWidth,
    spacedTop = true,
    spacedBottom = true,
    dividerProps,
    sx,
}: SectionProps): ReactElement => {
    const theme = useTheme()
    const breakpoint = useBreakpoint()

    const renderContent = (): ReactElement => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: breakpoint !== Breakpoint.S && alignment === 'right' ? 'flex-end' : 'flex-start',
                }}
            >
                {title && (
                    <Typography
                        variant="h2"
                        style={{
                            fontWeight: 'bold',
                            ...(variant === 'dark' ? { color: 'white' } : { color: theme.palette.primary.main }),
                        }}
                    >
                        {title}
                    </Typography>
                )}

                <Box
                    sx={{
                        marginTop: title ? '2rem' : 0,
                        width: alignment && breakpoint !== Breakpoint.S ? '66%' : '100%',
                    }}
                >
                    {caption && (
                        <Typography
                            fontSize="1.8rem"
                            sx={{
                                marginBottom: '3rem',
                            }}
                        >
                            {caption}
                        </Typography>
                    )}
                    {children}
                </Box>
            </Box>
        )
    }

    return (
        <Box
            sx={{
                width: '100%',
                marginTop: spacedTop ? '3.5rem' : 0,
                paddingTop: '3.5rem',
                paddingBottom: spacedBottom ? '3.5rem' : 0,
                position: 'relative',
                color: variant === 'light' ? theme.palette.text.primary : theme.palette.text.secondary,
                ...(variant === 'dark' ? { backgroundColor: theme.palette.primary.main } : {}),
                ...sx,
            }}
        >
            {dividerProps ? (
                <Box
                    sx={{
                        '@media screen and (max-width: 500px)': {
                            marginBottom: '2rem',
                        },
                        '@media screen and (max-width: 768px)': {
                            marginBottom: '3rem',
                        },
                    }}
                >
                    <SectionDivider colorVariant={variant} variant={0} {...dividerProps} />
                </Box>
            ) : null}
            {fullWidth ? renderContent() : <Container>{renderContent()}</Container>}
        </Box>
    )
}
