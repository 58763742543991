import React, { ReactElement } from 'react'
import { Section } from '../../molecules/Section'
import { Typography, useTheme } from '@mui/material'
import { useLocalization } from '../../localization/LocalizationProvider'

export const AboutUsSection = (): ReactElement => {
    const theme = useTheme()
    const { translate } = useLocalization()

    return (
        <Section title={translate('aboutUsTitle')}>
            <Typography
                style={{ fontWeight: 'bold', fontSize: '2rem' }}
                sx={{ '& > span': { color: theme.palette.primary.main } }}
            >
                {translate('aboutUsText')}
            </Typography>
        </Section>
    )
}
