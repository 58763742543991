import React, { ReactElement } from 'react'
import { Typography } from '@mui/material'
import { useLocalization } from '../../../localization/LocalizationProvider'

export const Mission = (): ReactElement => {
    const { translate } = useLocalization()

    return (
        <>
            <Typography variant="h2" color="white" style={{ fontWeight: 'bold' }}>
                {translate('missionOur')}
            </Typography>
            <Typography variant="h2" color="white" style={{ fontWeight: 'bold' }}>
                {translate('missionMission')}
            </Typography>
            <Typography color="white" style={{ fontSize: '1.8rem', marginTop: '2rem' }}>
                {translate('missionCaption')}
            </Typography>
        </>
    )
}
