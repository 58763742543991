import { Translations } from '../translations'
import React from 'react'

const gbEn: Translations = {
    uoLabs: 'UoLabs',
    landingCaption: 'Software and AI crafters',
    aboutUsTitle: 'About Us',
    aboutUsText: (
        <>
            We are software crafters of innovative and effective solutions that solve technical challenges using
            <span> Artificial Intelligence</span>,<span> Algorithmics</span> &<span> Software Engineering</span>
        </>
    ),
    missionOur: 'Our',
    missionMission: 'Mission',
    missionCaption: (
        <>
            Help people and companies <b>optimize low value and high effort</b> tasks, so professionals can focus on
            what really matters
        </>
    ),
    visionOur: 'Our',
    visionVision: 'Vision',
    visionCaption: (
        <>
            Become a top-notch company in turning <b>business needs</b> onto <b>AI solutions</b>
        </>
    ),
    dataAnalyticsTitle: 'Data Analytics',
    dataAnalyticsCaption: (
        <>
            We resolve regression, classification and clustering problems using <b>Machine Learning</b> and{' '}
            <b>Deep Learning</b> models.
        </>
    ),
    dataAnalyticsRobustScalableModels: 'Robust and scalable models',
    dataAnalyticsGeneralization: 'Generalization',
    aiFieldsTitle: 'AI Specific Fields',
    aiFieldsCaption: (
        <>
            Our main expertise on <b>Artificial Intelligence</b> is focused on:
        </>
    ),
    contactTitle: 'Contact Us',
    contactCaption: 'Feel free to contact us at any time. We will get back to you as soon as we can.',
    contactName: 'Name',
    contactEmail: 'Email',
    contactMessage: 'Message',
    contactSend: 'Send',
}

export default gbEn
