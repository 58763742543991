import React, { ReactElement } from 'react'
import { CommonSectionProps } from '../CommonSectionProps'
import { Section } from '../../molecules/Section'
import { BulletList, BulletListItem } from '../../molecules/BulletList'
import { useLocalization } from '../../localization/LocalizationProvider'

const AI_FIELDS_ITEMS: BulletListItem[] = [
    {
        icon: 'expand',
        title: 'Computer Vision',
    },
    {
        icon: 'expand',
        title: 'Natural Language Processing',
    },
    {
        icon: 'expand',
        title: 'Time Series',
    },
    {
        icon: 'expand',
        title: 'Optimization',
    },
]

type AIFieldsSectionProps = CommonSectionProps

export const AIFieldsSection = ({
    variant = 'light',
    alignment = 'left',
    divider = 0,
    sx,
    dividerSx,
}: AIFieldsSectionProps): ReactElement => {
    const { translate } = useLocalization()

    return (
        <Section
            variant={variant}
            alignment={alignment}
            spacedTop={false}
            title={translate('aiFieldsTitle')}
            caption={translate('aiFieldsCaption')}
            dividerProps={{
                variant: divider,
                sx: dividerSx,
            }}
            sx={{
                paddingTop: '20rem',
                ...sx,
            }}
        >
            <BulletList
                items={AI_FIELDS_ITEMS}
                variant={variant}
                columns={{
                    s: 1,
                    l: 2,
                }}
            />
        </Section>
    )
}
