import React, { ReactElement } from 'react'
import { MissionVisionSectionLarge } from './MissionVisionSectionLarge'
import { Box } from '@mui/material'
import { MissionVisionSectionSmall } from './MissionVisionSectionSmall'

import './MissionVisionSection.scss'

export const MissionVisionSection = (): ReactElement => {
    return (
        <>
            <Box className="mission-vision-section__large">
                <MissionVisionSectionLarge />
            </Box>
            <Box className="mission-vision-section__small">
                <MissionVisionSectionSmall />
            </Box>
        </>
    )
}
