import React, { ReactElement } from 'react'
import { Outlet } from 'react-router-dom'
import { Box, CssBaseline, ThemeProvider } from '@mui/material'
import { insectsTheme } from './theme/insectsTheme'
import { NavBar } from './molecules/NavBar/NavBar'

export const InsectsApp = (): ReactElement => {
    return (
        <ThemeProvider theme={insectsTheme}>
            <CssBaseline />

            <NavBar />

            <Box sx={{ paddingTop: '6.4rem' }}>
                <Outlet />
            </Box>

            <iframe
                src="https://gonzalorecio-insect-detection-demo-app-main-drz66u.streamlit.app/?embed=true&scroll=true"
                id="insects-app"
                width="100%"
                height="500px"
                onLoad={(): void => {
                    setTimeout((): void => {
                        const frame = document.getElementById('insects-app')
                        frame?.setAttribute('height', '4500px')
                    }, 5000)
                }}
            ></iframe>
        </ThemeProvider>
    )
}
