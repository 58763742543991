import { InsectsPrivacyPolicy } from './InsectsPrivacyPolicy'
import { Route } from 'react-router-dom'
import React, { ReactElement } from 'react'
import { InsectsApp } from './InsectsApp'

export const getInsectRoutes = (basePath: string): ReactElement => {
    return (
        <Route path={basePath} element={<InsectsApp />}>
            <Route key="privacy-policy" path="privacy-policy" element={<InsectsPrivacyPolicy />} />
        </Route>
    )
}
