import React, { ReactElement } from 'react'
import { Box, Container, useTheme } from '@mui/material'
import { Mission } from './components/Mission'
import { Vision } from './components/Vision'
import { SectionDivider } from '../../molecules/SectionDivider'

export const MissionVisionSectionSmall = (): ReactElement => {
    const theme = useTheme()

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Container
                style={{
                    paddingTop: '15rem',
                    paddingBottom: '5rem',
                    backgroundColor: theme.palette.primary.main,
                    position: 'relative',
                }}
            >
                <SectionDivider variant={1} colorVariant="dark" />
                <Mission />
            </Container>

            <Container
                style={{
                    paddingTop: '15rem',
                    paddingBottom: '5rem',
                    position: 'relative',
                    textAlign: 'right',
                }}
            >
                <SectionDivider variant={0} />
                <Vision />
            </Container>
        </Box>
    )
}
