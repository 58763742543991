import React, { ReactElement } from 'react'
import { Section } from '../../molecules/Section'
import { BulletList, BulletListItem } from '../../molecules/BulletList'
import { CommonSectionProps } from '../CommonSectionProps'
import { useLocalization } from '../../localization/LocalizationProvider'

const DATA_ANALYTICS_ITEMS: BulletListItem[] = [
    {
        icon: 'expand',
        titleKey: 'dataAnalyticsRobustScalableModels',
    },
    {
        icon: 'expand',
        titleKey: 'dataAnalyticsGeneralization',
    },
    {
        icon: 'expand',
        title: 'Ethical AI',
    },
    {
        icon: 'expand',
        title: 'Explainable AI',
    },
    {
        icon: 'expand',
        title: 'Risk assesment',
    },
    {
        icon: 'expand',
        title: 'Data anonymization',
    },
    {
        icon: 'expand',
        title: 'Real world solutions',
    },
    {
        icon: 'expand',
        title: 'Monitored performance',
    },
]

type DataAnalyticsSectionProps = CommonSectionProps

export const DataAnalyticsSection = ({
    variant = 'light',
    alignment = 'left',
    divider = 0,
    sx,
    dividerSx,
}: DataAnalyticsSectionProps): ReactElement => {
    const { translate } = useLocalization()

    return (
        <Section
            variant={variant}
            alignment={alignment}
            spacedTop={false}
            title={translate('dataAnalyticsTitle')}
            caption={translate('dataAnalyticsCaption')}
            dividerProps={{
                variant: divider,
                sx: dividerSx,
            }}
            sx={{
                paddingTop: '20rem',
                ...sx,
            }}
        >
            <BulletList
                items={DATA_ANALYTICS_ITEMS}
                variant={variant}
                columns={{
                    s: 1,
                    l: 2,
                }}
            />
        </Section>
    )
}
