import React, { ReactElement } from 'react'
import { Box, Container, Typography } from '@mui/material'

import './Footer.scss'

export const Footer = (): ReactElement => {
    return (
        <Box className="footer">
            <Container>
                <Box sx={{ textAlign: 'center', padding: '1rem' }}>
                    <Typography>Copyright 2022 © UoLabs Technology S.L.</Typography>
                </Box>
            </Container>
        </Box>
    )
}
