import { createTheme } from '@mui/material'
import { fontFaces } from '../../assets/fonts/fontFaces'

export const insectsTheme = createTheme({
    palette: {
        primary: {
            main: '#fff700',
        },
        text: {
            primary: 'rgba(0, 0, 0, 0.84)',
            secondary: 'white',
        },
    },
    typography: {
        fontFamily: 'Poppins',
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                ${fontFaces.join('\n')}
            `,
        },
    },
})
