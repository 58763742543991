import React, { ReactElement } from 'react'
import { Box, Container, List, ListItem, Typography } from '@mui/material'

export const InsectsPrivacyPolicy = (): ReactElement => {
    return (
        <Container>
            <Box
                sx={{
                    paddingTop: '2rem',
                    paddingBottom: '4rem',
                    '& h1': {
                        fontWeight: 'bold',
                        fontSize: '4.5rem',
                        marginBottom: '2rem',
                    },
                    '& h2': {
                        fontWeight: 'bold',
                        fontSize: '3rem',
                        marginTop: '1.6rem',
                        marginBottom: '1rem',
                    },
                }}
            >
                <Typography variant="h1">Privacy Policy</Typography>
                <Typography paragraph>
                    UoLabs Technology S.L. built the Automatic Insect Counter app as a Freemium app. This SERVICE is
                    provided by UoLabs Technology S.L. at no cost and is intended for use as is.
                </Typography>
                <Typography paragraph>
                    This page is used to inform visitors regarding our policies with the collection, use, and disclosure
                    of Personal Information if anyone decided to use our Service.
                </Typography>
                <Typography paragraph>
                    If you choose to use our Service, then you agree to the collection and use of information in
                    relation to this policy. The Personal Information that we collect is used for providing and
                    improving the Service. We will not use or share your information with anyone except as described in
                    this Privacy Policy.
                </Typography>
                <Typography paragraph>
                    The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which
                    are accessible at Automatic Insect Counter unless otherwise defined in this Privacy Policy.
                </Typography>
                <Typography variant="h2">Information Collection and Use</Typography>
                <Typography paragraph>
                    For a better experience, while using our Service, we may require you to provide us with certain
                    personally identifiable information, including but not limited to Camera. The information that we
                    request will be retained by us and used as described in this privacy policy.
                </Typography>
                <Typography paragraph>
                    The app does use third-party services that may collect information used to identify you.
                </Typography>
                <Typography paragraph>
                    Link to the privacy policy of third-party service providers used by the app
                </Typography>
                <List>
                    <ListItem>
                        <a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">
                            Google Play Services
                        </a>
                    </ListItem>
                    <ListItem>
                        <a href="https://expo.io/privacy" target="_blank" rel="noopener noreferrer">
                            Expo
                        </a>
                    </ListItem>
                </List>
                <Typography variant="h2">Log Data</Typography>
                <Typography paragraph>
                    We want to inform you that whenever you use our Service, in a case of an error in the app we collect
                    data and information (through third-party products) on your phone called Log Data. This Log Data may
                    include information such as your device Internet Protocol (“IP”) address, device name, operating
                    system version, the configuration of the app when utilizing our Service, the time and date of your
                    use of the Service, and other statistics.
                </Typography>
                <Typography variant="h2">Cookies</Typography>
                <Typography paragraph>
                    Cookies are files with a small amount of data that are commonly used as anonymous unique
                    identifiers. These are sent to your browser from the websites that you visit and are stored on your
                    device&apos;s internal memory.
                </Typography>
                <Typography paragraph>
                    This Service does not use these “cookies” explicitly. However, the app may use third-party code and
                    libraries that use “cookies” to collect information and improve their services. You have the option
                    to either accept or refuse these cookies and know when a cookie is being sent to your device. If you
                    choose to refuse our cookies, you may not be able to use some portions of this Service.
                </Typography>
                <Typography variant="h2">Service Providers</Typography>
                <Typography paragraph>
                    We may employ third-party companies and individuals due to the following reasons:
                </Typography>
                <List sx={{ marginBottom: '1rem' }}>
                    <ListItem>To facilitate our Service;</ListItem>
                    <ListItem>To provide the Service on our behalf;</ListItem>
                    <ListItem>To perform Service-related services; or</ListItem>
                    <ListItem>To assist us in analyzing how our Service is used.</ListItem>
                </List>
                <Typography paragraph>
                    We want to inform users of this Service that these third parties have access to their Personal
                    Information. The reason is to perform the tasks assigned to them on our behalf. However, they are
                    obligated not to disclose or use the information for any other purpose.
                </Typography>
                <Typography variant="h2">Security</Typography>
                <Typography paragraph>
                    We value your trust in providing us your Personal Information, thus we are striving to use
                    commercially acceptable means of protecting it. But remember that no method of transmission over the
                    internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its
                    absolute security.
                </Typography>
                <Typography variant="h2">Children’s Privacy</Typography>
                <Typography paragraph>
                    These Services do not address anyone under the age of 13. We do not knowingly collect personally
                    identifiable information from children under 13 years of age. In the case we discover that a child
                    under 13 has provided us with personal information, we immediately delete this from our servers. If
                    you are a parent or guardian and you are aware that your child has provided us with personal
                    information, please contact us so that we will be able to do the necessary actions.
                </Typography>
                <Typography variant="h2">Changes to This Privacy Policy</Typography>
                <Typography paragraph>
                    We may update our Privacy Policy from time to time. Thus, you are advised to review this page
                    periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on
                    this page.
                </Typography>
                <Typography paragraph>This policy is effective as of 2022-10-19.</Typography>
                <Typography variant="h2">Contact Us</Typography>
                <Typography paragraph>
                    If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at
                    contact@uolabs.tech.
                </Typography>
                <Typography paragraph>
                    This privacy policy page was created at{' '}
                    <a href="https://privacypolicytemplate.net" target="_blank" rel="noopener noreferrer">
                        privacypolicytemplate.net
                    </a>{' '}
                    and modified/generated by{' '}
                    <a
                        href="https://app-privacy-policy-generator.nisrulz.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        App Privacy Policy Generator
                    </a>
                </Typography>
            </Box>
        </Container>
    )
}
