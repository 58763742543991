import React, { ReactElement, useMemo } from 'react'
import { Box } from '@mui/material'
import { BulletItem } from './BulletItem'
import { SxProps } from '@mui/system'
import { Theme } from '@emotion/react'
import { Breakpoint, useBreakpoint } from '../../hooks/useBreakpoint'
import { TranslationKey } from '../../localization/translations'
import { useLocalization } from '../../localization/LocalizationProvider'

export interface BulletListItem {
    icon?: string
    title?: string
    titleKey?: TranslationKey
}

interface BulletListProps {
    items: BulletListItem[]
    columns?:
        | number
        | {
              s?: number
              m?: number
              l?: number
              xl?: number
          }
    variant?: 'light' | 'dark'
    sx?: SxProps<Theme>
}

export const BulletList = (props: BulletListProps): ReactElement => {
    const { items, variant = 'light', sx } = props

    const breakpoint = useBreakpoint()
    const { translate } = useLocalization()

    const columns = useMemo((): number => {
        if (typeof props.columns === 'number') return props.columns

        switch (breakpoint) {
            case Breakpoint.S:
                return props.columns?.s ?? 1
            case Breakpoint.M:
                return props.columns?.m ?? props.columns?.s ?? 1
            case Breakpoint.L:
                return props.columns?.l ?? props.columns?.m ?? props.columns?.s ?? 1
            case Breakpoint.XL:
                return props.columns?.xl ?? props.columns?.l ?? props.columns?.m ?? props.columns?.s ?? 1
        }

        return 1
    }, [breakpoint, props.columns])

    const getRows = (): BulletListItem[][] => {
        const rows: BulletListItem[][] = []

        for (let i = 0; i < items.length; i += columns) {
            const row = items.slice(i, i + columns)
            rows.push(row)
        }

        return rows
    }

    const renderRow = (row: BulletListItem[], rowIndex: number): ReactElement => {
        const rowKey = `bullet-row__${rowIndex}`
        return (
            <Box
                key={rowKey}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '3rem',
                }}
            >
                {row.map((item, index) => (
                    <BulletItem
                        key={`${rowKey}__item__${index}`}
                        title={item.titleKey ? translate(item.titleKey) : item.title ?? ''}
                        icon={item.icon}
                        variant={variant}
                        sx={{
                            flex: 1,
                        }}
                    />
                ))}
            </Box>
        )
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1.4rem',
                ...sx,
            }}
        >
            {getRows().map(renderRow)}
        </Box>
    )
}
