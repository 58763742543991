import { createContext, useContext } from 'react'
import { Translation, TranslationKey } from './translations'

interface LocalizationState {
    translate: (key: TranslationKey) => Translation
}

export const LocalizationContext = createContext<LocalizationState>({} as any)

export const useLocalization = (): LocalizationState => useContext<LocalizationState>(LocalizationContext)
